export function getPartnerDiscountType() {
    return [
        {
            value: "promocode",
            label: "Промокод"
        },
        {
            value: "certificate",
            label: "Сертификат"
        },
    ]
}