import { Tag } from "antd"
import styled from "styled-components"

export function getColumns(discountTypeOptions) {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "ascend",
      width: 100
    },
    {
      title: "Email получателя",
      dataIndex: "recipientEmail",
      key: "recipientEmail",
      sorter: (a, b) => a.recipientEmail - b.recipientEmail,
      width: 250
    },
    {
      title: "Сообщение",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (text) => (
          <StyledTag>
            {discountTypeOptions.find((type) => type.value === text)?.label ?? ""}
          </StyledTag>
      ),
      width: 200
    },
    {
      title: "Код/Сумма",
      key: "code_sum",
      render: (_, record) => (
          <>
            {record.type === "promocode" &&
                <StyledTag>
                  {record.code}
                </StyledTag>
            }
            {record.type === "certificate" &&
                <StyledTag>
                  {record.sum} руб.
                </StyledTag>
            }
          </>
      ),
      width: 250
    }
  ]
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`