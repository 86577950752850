export const STATE_MEDIA = process.env.REACT_APP_API_URL;
export const API_BASE_MEDIA_URL = `${STATE_MEDIA}`;
export const API_BASE_URL = `${STATE_MEDIA}api/dashboard/`;

export const API_BASE_URL_V2 = `${STATE_MEDIA}api/v2/dashboard/`;

export const LOCAL_STORAGE_NAME = "bushe_local";

export const DATE_FORMAT = "DD.MM.YYYY"

export const DELiVERY_GUID = "602eeacd-abe1-44de-8428-fe0204c767ef"
