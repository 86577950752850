import React, {useState} from "react"
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons"
import {
  Col,
  Divider,
  Drawer,
  Form, Input,
  InputNumber, notification,
  Row,
  Typography,
  Upload,
} from "antd"

import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import styled from "styled-components"
import {SearchBox} from "../../components/search-box";
import {SearchProducts} from "../point-products/model/use-get-point-products";
import {useUploadFile} from "../../hooks/use-upload-file";
import {API_BASE_MEDIA_URL} from "../../config";
import {getInitialValues} from "../../utils/helpers";
import moment from "moment/moment";

const { Title } = Typography

export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave,
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)
  const [fileList, setFileList] = React.useState({});
  const [productOptions, setProductOptions] = useState([])
  const [productId, setProductId] = useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorLength, setErrorLength] = React.useState(false)

  function getImage(image) {
    const file = {
      url: API_BASE_MEDIA_URL + image.path + "/" + image.systemName,
      uid: String(image.id),
      name: image.originalName,
      status: "done",
    }

    return [file]
  }

  React.useEffect(() => {
    if (initialValues) {
      setProductId(initialValues.productId.value)
      form.setFieldsValue(initialValues);

      const updatedFileList = {};

      if (initialValues.videos) {
        initialValues.videos.forEach((video, index) => {
          updatedFileList[index] = getImage(video.video);
        });
      }
      setFileList(updatedFileList)
    }
  }, [initialValues])

  const [form] = Form.useForm()

  function getVideos(videos) {
    return videos.map((videoObj, index) => {
      const { video, file, videoId, ...rest } = videoObj;
      const fileId = fileList[index][0]?.uid;
      const isOld = !!initialValues?.videos?.find((oldVideo) => Number(oldVideo.video.id) === Number(fileId));

      return {
        ...rest,
        fileId: Number(fileId),
        ...(!isOld && { isAdded: 1 })
      };
    }).concat(
        initialValues?.videos
            ?.filter((videoObj) => !videos.find((newVideo => newVideo.id === videoObj.id)))
            .map(({ video, ...rest }) => ({ ...rest, isDeleted: 1 })) || []
    )
  }

  async function onSubmit(values) {
    if (values.videos?.length > 0) {
      setErrorLength(false)
      setIsLoading(true)

      const id = initialValues?.id

      const data = {
        ...(id && { id, _method: "PUT" }),
        productId: values.productId.value,
      }

      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => formData.append(key, value))

      const newVideos = getVideos(values.videos)
      console.log("newVideos: ", newVideos)
      newVideos.forEach((video, index) => {
        Object.entries(video).forEach(([key, value]) => {
          formData.append(`videos[${index}][${key}]`, value)
        })
      })

      try {
        await onSave(formData, initialValues?.id)

        onClose()
        form.resetFields()
      } finally {
        setIsLoading(false)
      }
    } else {
      setErrorLength(true)
    }
  }

  async function handleSearchProduct(name) {
    const options = await SearchProducts(name)
    setProductOptions(options)
  }

  function handleRemoveVideo(name) {
    setFileList((prev) => {
      const updatedVideos = { ...prev };
      delete updatedVideos[name];
      return updatedVideos;
    });
  }

  const handleChangeProduct = (newValue) => {
    setProductId(newValue);
  };

  function handleClose() {
    onClose();
    form.resetFields();
  }

  async function handleBeforeUpload(file, fieldPath, index = null) {

    const isVideo = file.type.startsWith("video/");

    if (!isVideo ) {
      notification.error({ message: "Можно загружать только видео!" });
      return Upload.LIST_IGNORE;
    }

    const uploadedFile = await useUploadFile(file);
    if (uploadedFile?.id) {
      const newFile = {
        uid: String(uploadedFile.id),
        url: API_BASE_MEDIA_URL + uploadedFile.path + "/" + uploadedFile.systemName,
        name: file.name,
        status: "done",
      };

      setFileList((prev) => ({
        ...prev,
        [index]: [newFile],
      }));
      form.setFields([{ name: fieldPath, value: uploadedFile.id }]);
    }
    return false;
  }


  const id = initialValues?.id ?? "new"

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit
            form={form}
            layout={"vertical"}
            onFinish={(values) => onSubmit(values)}
        >
          <Divider orientation="left">Продукт</Divider>


          <StyledFormItem
              key={id + "_productId"}
              label={"Продукт"}
              name={"productId"}
              rules={[
                {
                  required: true,
                  message: "Выберите продукт!",
                },
              ]}
          >
            <SearchBox
                labelInValue
                options={productOptions}
                handleSearch={handleSearchProduct}
                onChange={handleChangeProduct}
            />
          </StyledFormItem>
          {productId && (
              <>
                <Divider orientation="left">Видео карточки</Divider>

                { errorLength && <div style={{ color: "red" }}>Добавьте хотя-бы 1-ну карточку!</div>}

                <Form.List name="videos">
                  {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...field }, index) => (
                            <React.Fragment key={`RowTitle_${index}`}>
                              <RowTitle level={4}>
                                Карточка {key + 1}{" "}
                                {fields.length > 0 ? (
                                    <MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        style={{ marginLeft: "12px" }}
                                        onClick={() => {
                                          handleRemoveVideo(name)
                                          remove(name);
                                        }}
                                    />
                                ) : null}
                              </RowTitle>

                              <Form.Item required={false} key={key}>
                                <StyledFormItem
                                    name={[name, "video"]}
                                    rules={[{ required: true, message: "Загрузите видео!" }]}
                                >
                                  <Upload
                                      listType={"text"}
                                      maxCount={1}
                                      accept="video/mp4"
                                      beforeUpload={(file) =>
                                          handleBeforeUpload(file, ["videos", name, "video"], name)
                                      }
                                      fileList={Array.isArray(fileList[name]) ? fileList[name] : []}
                                      onRemove={() => handleRemoveVideo(name)}
                                  >
                                    <Button icon={<PlusOutlined />}>Загрузить видео (.mp4)</Button>
                                  </Upload>
                                </StyledFormItem>
                                <StyledFormItem
                                    {...field}
                                    key={id + "_sortWeight"}
                                    label={"Позиция в списке"}
                                    name={[name, "sortWeight"]}
                                    rules={[
                                      {
                                        message: "Введите позицию!",
                                        required: true,
                                      },
                                    ]}
                                >
                                  <InputNumber
                                      min={2}
                                      style={{
                                        width: "100%",
                                      }}
                                  />
                                </StyledFormItem>


                              </Form.Item>
                            </React.Fragment>
                        ))}

                        <Row gutter={28}>
                          <Col className="gutter-row" span={12}>
                            <Form.Item>
                              <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                              >
                                Добавить
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                  )}
                </Form.List>
              </>
          )}

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.resetFields(["image"])
                      form.setFieldsValue(getInitialValues(initialValues))
                      return
                    }
                    form.resetFields()
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button icon={<SaveSvg />} type="primary" htmlType="submit" disabled={isLoading}>
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const RowTitle = styled(Title)`
  margin-bottom: 20px;
`

