import React from "react";
import {Form, Input} from "antd";
import styled from "styled-components";
import {RotateClockwiseSvg} from "../../components/Svg";
import {Button} from "../../components/actions/button";
import {SearchOutlined} from "@ant-design/icons";

export function ProductVideosFilers({handleSearch, handleClearFilter}) {

    const [form] = Form.useForm()

    function onSearch(values){
        let data = {
            name: values.name?.trim() ?? null,
        }
        handleSearch(data)
    }

    function onClear() {
        form.resetFields()
        handleClearFilter()

    }

    return (
        <>
           <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSearch(values)}>
               <StyledFieldsContainer>
                   <StyledFormItem
                       key={ "sort_name" }
                       label={"По названию продукта"}
                       name={"name"}
                   >
                       <Input placeholder="Название продукта" />
                   </StyledFormItem>
               </StyledFieldsContainer>
               <ButtonsContainer>
                   <Button
                       danger
                       onClick={onClear}
                       icon={<RotateClockwiseSvg />}
                   >
                       Сбросить
                   </Button>
                   <Button icon={<SearchOutlined />}  type="primary" htmlType="submit">
                       Искать
                   </Button>
               </ButtonsContainer>

           </FormEdit>
        </>
    )
}

const FormEdit = styled(Form)`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin: 20px 0;
`

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 10px;
`

const ButtonsContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`

const StyledFieldsContainer = styled("div")`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 20px;
`