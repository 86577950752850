import {useGetData, useManageItems} from "../pages-lib/product-video/model";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import React from "react";
import {VisibilityProvider} from "../components/visibility-provider";
import {EditDrawer} from "../pages-lib/product-video/edit-drawer";
import {getColumns} from "../pages-lib/product-video/table-columns";
import styled from "styled-components";
import { Table as AntTable } from "antd"
import {ProductVideosFilers} from "../pages-lib/product-video/filters";

export default function ProductVideo() {
    const [createDrawerState, setCreateDrawerState] = React.useState(false)
    const [editDrawerState, setEditDrawerState] = React.useState(false)

    function onSuccessCreate() {
        setCreateDrawerState(state => !state)
    }

    function onSuccessEdit() {
        setEditDrawerState(state => !state)
    }

    const { dataItems, refetch: getItems, isLoading, pagination, handleTableChange, handleSearchTable, handleClearFilter } = useGetData()

    const {onCreateItem, onSaveEdit, onDelete, onEditItem, editData} = useManageItems(dataItems, getItems, onSuccessEdit, onSuccessCreate)

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 записей",
        arrType: ["запись", "записи", "записей"],
    })

    const columns = getColumns(onEditItem, onDelete)

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(true)}
                        titles={{ onCreate: "Добавить карточки" }}
                    />
                }
            />

            <ProductVideosFilers handleSearch={handleSearchTable} handleClearFilter={handleClearFilter}/>

            <StyledTable
                dataSource={dataItems}
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    title={"Создание записи"}
                    onClose={() => setCreateDrawerState(false)}
                    initialValues={false}
                    onSave={onCreateItem}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    title={"Редактирование записи"}
                    onClose={() => setEditDrawerState(false)}
                    initialValues={editData}
                    onSave={onSaveEdit}
                />
            </VisibilityProvider>
        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`