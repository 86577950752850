import React from "react"
import { notification } from "antd"
import api from "src/api"
import { getOldImage } from "src/utils/helpers"

export function useManageItems(getItems, onSuccessCreate) {
  async function onCreate(values) {
    try {
      await api.post("partner-discounts", values, {useV2Api: true})

      notification.success({ message: "Скидка добавлена" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }


  return {onCreate}
}
