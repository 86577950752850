import {notification} from "antd";
import api from "../api";

export const useUploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
        const { data } = await api.post("files", formData)

        return data.uploadedFile
    } catch (error) {
        notification.error({ message: "Ошибка загрузки файла" })
    }
}