import React from "react"
import { notification } from "antd"
import api from "src/api"

export function useManageItems(items, getItems) {
  const [updatingStory, updateStory] = React.useState(null)

  function onCreateItem() {
    updateStory({ state: "create" })
  }

  function onEditItem(id) {
    const story = items.find((story) => story.id === id)
    updateStory({ state: "edit", story })
  }

  async function onDelete(id) {
    try {
      await api.delete(`stories/${id}`, {useV2Api: true})
      notification.success({ message: "История удалёна" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
    }
  }

  async function onSave(values, id, isPut) {
    const method = id && isPut ? `stories/${id}` : "stories"

    try {
      await api.post(method, values, {useV2Api: true})

      notification.success({
        message: isPut ? "Группа изменена" : "Группа добавлена",
      })
      updateStory(null)
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  function resetItem() {
    updateStory(null)
  }

  return { updatingStory, onSave, onDelete, onEditItem, onCreateItem, resetItem }
}
