import React from "react"
import api from "src/api"

export function useGetStories() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false);
  const [stories, setStories] = React.useState([])

  async function getStories(page = 1, countOnPage = 20) {
    setIsLoading(true);
      try {
        const { data } = await api.get(`stories?page=${page}&countOnPage=${countOnPage}`, {useV2Api: true})
          setPagination({
            current: data.pagination.currentPage,
            pageSize: data.pagination.countOnPage,
            total: data.pagination.total
          });
          setStories(data.items)

        setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const handleTableChange = (pagination) => {
    getStories(pagination.current, pagination.pageSize )
  };

  React.useEffect(() => {
    getStories()
  }, [])

  return { stories, pagination, refetch: () => getStories(), isLoading, handleTableChange }
}
