import React from "react"
import {
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,

} from "antd"
import { Button } from "src/components/actions/button"
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import {
  getInitialValues,
} from "src/utils/helpers"
import styled from "styled-components"

const { TextArea } = Input


export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave,
                             typeDiscountOptions
                           }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

    const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(getInitialValues(initialValues))
    }
  }, [initialValues])

  const [form] = Form.useForm()

  async function onSubmit(values) {
      setIsLoading(true)
      try {
          await onSave(values)

          onClose()
          form.resetFields()
      } finally {
          setIsLoading(false)
      }
  }

  function handleClose() {
    onClose()
    form.resetFields()
  }


  const id = initialValues?.id ?? "new"

  const type = Form.useWatch("type", form)

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit
            form={form}
            layout={"vertical"}
            onFinish={(values) => onSubmit(values)}
        >
          <StyledFormItem
              key={id + "_recipientEmail"}
              label={"Email получателя"}
              name={"recipientEmail"}
              rules={[
                {
                  required: true,
                  message: "Введите email!",
                },
                {
                  type: "email",
                  message: "Введите правильный email!",
                },
              ]}
          >
            <Input
                type={"email"}
                placeholder={"Введите email"}
            />
          </StyledFormItem>

          <StyledFormItem
              key={id + "_type"}
              label={"Тип скидки"}
              name={"type"}
              rules={[
                {
                  required: true,
                  message: "Выберите тип!",
                },
              ]}
          >
            <Select options={typeDiscountOptions} placeholder={"Выберите тип"}/>
          </StyledFormItem>

          {type === "promocode" && (
              <StyledFormItem
                  key={id + "_code"}
                  label={"Код"}
                  name={"code"}
                  rules={[
                      {
                          required: true,
                          message: "Введите код",
                      },
                  ]}
              >
                  <Input placeholder={"Код"} />
              </StyledFormItem>
          )}

          {type === "certificate" && (
              <StyledFormItem
                  key={id + "_sum"}
                  label={"Сумма скидки"}
                  name={"sum"}
                  rules={[
                      {
                          required: true,
                          message: "Введите сумму скидки",
                      },
                  ]}
              >
                  <InputNumber
                      min={1}
                      style={{
                          width: "100%",
                      }}
                      placeholder={"Введите сумму скидки"}
                  />
              </StyledFormItem>
          )}

          <StyledFormItem
              key={id + "_message"}
              label={"Сообщение"}
              name={"message"}
              rules={[
                {
                  required: true,
                  message: "Введите сообщение!",
                },
              ]}
          >
            <TextArea
                rows={4}
                placeholder={"Сообщение"}
                maxLength={1000}
            />
          </StyledFormItem>

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.resetFields(["image"])
                      form.setFieldsValue(getInitialValues(initialValues))
                      return
                    }
                    form.resetFields()
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button icon={<SaveSvg />} disabled={isLoading} type="primary" htmlType="submit">
                Сохранить
              </Button>
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

