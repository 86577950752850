import React from "react"
import api from "src/api"

export function useGetData() {
  const [dataItems, setDataItems] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState(null)
  const [filterState, setFilterState] = React.useState(null)

  React.useEffect(() => {
    getItems()
  }, [])

  const getItems = async (page = 1, countOnPage = 20, filters = "") => {
    setIsLoading(true)
    try {
      const { data } = await api.get(`product-video?page=${page}&countOnPage=${countOnPage}${filters}`, {useV2Api: true})
      setPagination({
        current: data.pagination?.currentPage ?? 1,
        pageSize: data.pagination?.countOnPage ?? 20,
        total: data.pagination?.total ?? 20,
      })
      const formattedItemsData = formattedItems(data.items)
      setDataItems(formattedItemsData)
    } catch (e) {
      console.error("Error: ", e)
    } finally {
      setIsLoading(false)
    }
  }

  function handleTableChange(paginationData) {
    if (filterState) {
      handleSearchTable(filterState, paginationData)
    } else {
      getItems(paginationData.current, paginationData.pageSize)
    }
  }

  function handleSearchTable(filters, paginationSet = null) {
    let filters_str = ""
    let count = 0;
    for (let [key, value] of Object.entries(filters)) {
      if(value && value !== "") {
        switch (key) {
          case "name":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=name->>'ru'&filters[${count}][v]=${value}`
            break;
        }
        count++
      }
    }
    if (filters_str && filters_str !== "") {
      setFilterState(filters)
    } else {
      setFilterState(null)
    }
    if (paginationSet) {
      getItems(paginationSet.current, paginationSet.pageSize, filters_str)
    } else {
      getItems(1,  20, filters_str)
    }
  }

  function handleClearFilter() {
    setFilterState(null)
    if (pagination) {
      getItems(pagination.current, pagination.pageSize)
    } else {
      getItems()
    }
  }

  function formattedItems(items) {
    return items.map((item) => {
      return {
        ...item,
        key: item.productId
      }
    })
  }


  return {
    dataItems,
    pagination,
    isLoading,
    handleTableChange,
    handleSearchTable,
    handleClearFilter,
    refetch: () => getItems(),
  }
}
