import { Popconfirm, Space, Tag, Tooltip } from "antd"
import { Button } from "src/components/actions/button"
import { PencilSvg, TrashSvg } from "src/components/Svg"
import styled from "styled-components"

export function getColumns(onEdit, onDelete) {
  return [
    {
      title: "ID Продукта",
      dataIndex: "productId",
      key: "productId",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Название",
      dataIndex: "shortName",
      key: "shortName"
    },
    {
      title: "Количесво видео карточек",
      dataIndex: "videos",
      key: "videos",
      render: (videos) => (
          <StyledTag>{videos.length}</StyledTag>
      ),
    },
    {
      title: "Действие",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          <Tooltip placement="top" title="Редактировать" mouseLeaveDelay={0}>
            <Button size="small" type="link" onClick={() => onEdit(record.productId)}>
              <PencilSvg />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title="Удалить" mouseLeaveDelay={0}>
            <Popconfirm
              placement="left"
              title="Действительно хотите удалить?"
              onConfirm={() => onDelete(record.productId)}
              onCancel={() => stopPropagation}
            >
              <Button size="small" type="link" onClick={stopPropagation}>
                <TrashSvg />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      ),
    },
  ]
}

const stopPropagation = (e) => {
  e?.stopPropagation()
}

const StyledTag = styled(Tag)`
  font-weight: 600;
  text-transform: uppercase;
`
