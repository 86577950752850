import React from "react"
import { notification } from "antd"
import api from "src/api"
export function useManageItems(items, getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  async function onEditItem(id) {
    const item = items.find((product) => product.productId === id)
    const formattedEdit = formattedEditData(item)
    setEditData(formattedEdit)
    onSuccessEdit()
  }

  async function onCreateItem(values) {
    try {
      await api.post(`product-video`, values, {useV2Api: true})

      notification.success({ message: "Запись добавлена" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: error.response?.data?.message ?? "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`product-video/${id}`, values, {useV2Api: true})

      notification.success({ message: "Запись изменена" })
      getItems()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`product-video/${id}`, {useV2Api: true})

      notification.success({ message: "Запись удалена" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  const formattedEditData = (item) => {
    const productId = {
      label: item.shortName,
      value: item.productId,
    }
    return {
      key: item.productId,
      id: item.productId,
      productId: productId,
      videos: item.videos.map((video) => ({video: video.file, sortWeight: video.sortWeight}))
    }
  }

  function resetData() {
    setEditData(null)
  }

  return { editData, resetData, onSaveEdit, onCreateItem, onDelete, onEditItem }
}
