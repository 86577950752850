import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import React from "react";
import {EditDrawer} from "../pages-lib/partner-discounts/edit-drawer";
import {useGetData , useManageItems} from "../pages-lib/partner-discounts/model";
import {PartnerDiscountFilers} from "../pages-lib/partner-discounts/filters";
import {VisibilityProvider} from "../components/visibility-provider";
import {getColumns} from "../pages-lib/partner-discounts/table-columns";
import { Table as AntTable } from "antd"
import styled from "styled-components";

export default function PartnerDiscounts() {

    const [createDrawerState, setCreateDrawerState] = React.useState(false);

    const {
        dataDiscounts,
        pagination,
        isLoading,
        handleTableChange,
        handleSearchTable,
        handleClearFilter,
        refetch: getItems,
        options
    } = useGetData()

    const {
        onCreate,
    } = useManageItems(getItems, onSuccessCreate)

    const headerTitle = useUpdateHeaderTitle({
        items: [],
        defaultTitle: "0 Скидок",
        arrType: ["Скидка", "Скидки", "Скидок"],
    })

    function onSuccessCreate() {
        setCreateDrawerState((state) => !state)
    }

    const columns = getColumns(options.discountTypeOptions)

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(true)}
                        titles={{ onCreate: "Добавить скидку" }}
                    />
                }
            />

            <PartnerDiscountFilers handleClearFilter={handleClearFilter} handleSearch={handleSearchTable}/>

            <StyledTable
                dataSource={dataDiscounts}
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    title={"Создание партнерской скидки"}
                    initialValues={false}
                    onSave={onCreate}
                    onClose={() => setCreateDrawerState(false)}
                    typeDiscountOptions={options.discountTypeOptions}
                />
            </VisibilityProvider>
        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`