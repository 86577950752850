import React from "react"
import moment from "moment"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { API_BASE_MEDIA_URL } from "src/config"
import { useUpdateHeaderTitle } from "src/hooks"
import { EditStory } from "src/pages-lib/stories-v2/edit-story"
import { useGetStories, useManageItems } from "src/pages-lib/stories-v2/model"
import styled from "styled-components";
import {Table as AntTable} from "antd";
import {getColumns} from "src/pages-lib/stories-v2/table-columns";


export default function StoriesV2() {

  const { stories, refetch: getStories, pagination, isLoading, handleTableChange } = useGetStories()

  const {
    updatingStory,
    onSave,
    onDelete,
    onEditItem: onEdit,
    onCreateItem,
    resetItem,
  } = useManageItems(stories, getStories)

  const headerTitle = useUpdateHeaderTitle({
    items: stories,
    defaultTitle: "0 историй",
    arrType: ["история", "истории", "историй"],
  })

  const columns = getColumns(onEdit, onDelete);


  const editTitle =
    updatingStory?.state === "edit" ? "Редактировать группу" : "Создать группу"

  return (
    <div>
      <Header
        title={headerTitle}
        extra={
          <ExtraButtons
            onCreate={onCreateItem}
            titles={{ onCreate: "Создать историю" }}
          />
        }
      />
      <StyledTable
          dataSource={stories}
          columns={columns}
          pagination={pagination}
          loading={isLoading}
          onChange={handleTableChange}
      />


      <EditStory
        onSave={onSave}
        onClose={() => resetItem()}
        title={editTitle}
        state={updatingStory?.state}
        initialValues={getData(updatingStory?.story)}
      />

    </div>
  )
}

function getData(item) {
  if (!item) {
    return null
  }
  return {
    ...item,
    date: [
      moment(item.startedAt, "YYYY-MM-DD"),
      moment(item.finishedAt, "YYYY-MM-DD"),
    ]
  }
}

function getImage(image) {
  const file = {
    url: API_BASE_MEDIA_URL + image.path + "/" + image.systemName,
    uid: String(image.id),
    name: image.originalName,
    status: "done",
  }

  return [file]
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`

